<template>
  <div class="relative bg-white border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-primary focus-within:border-primary">
    <label v-if="attrs.label" :for="attrs.id" class="absolute bg-white -top-2 left-2 -mt-px inline-block px-1 text-xs font-medium text-gray-900">{{ attrs.label }}</label>
    <input :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" v-bind="attrs" class="outline-none block w-full border-0 p-0 text-gray-800 placeholder-gray-500 focus:ring-0 sm:text-sm" />
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: true,
    },
    classes: {
      type: Object,
    },
    modelValue: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      attrs: {
        id: this.$props.id,
        type: this.$props.type,
        name: this.$props.name,
        label: this.$props.label,
        placeholder: this.$props.placeholder,
      },
      classesLabel: this.$props.classes.label,
      classesInput: this.$props.classes.input,
    };
  },
};
</script>

<!--
  Usage:
    <template>
      <h1>Forgoot password</h1>
      <default-input v-model="formdata.email" v-bind="form.email.attrs" :classes="form.email.classes"></default-input>
    </template>

    <script>
    import defaultInput from "@/components/InputGroups/DefaultInput.vue";

    export default {
      components: {
        defaultInput,
      },
      data() {
        return {
          formdata: {
            email: "",
          },
          form: {
            email: {
              attrs: {
                id: "email",
                type: "email",
                name: "email",
                placeholder: "email@email.dk",
              },
              classes: {
                label: [],
                input: [],
              },
            },
          },
        };
      },
    };
    </script>


  Src: https://learnvue.co/2021/01/everything-you-need-to-know-about-vue-v-model/

-->
